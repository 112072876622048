exports.korean = {
  nav_items: [
    {
      link: "#home",
      name: "Home",
    },
    {
      link: "#services",
      name: "My Services",
    },
    {
      link: "#about",
      name: "About me",
    },
    {
      link: "#work",
      name: "My Work",
    },
  ],
  introduction: {
    title: "Hi, I'm",
    name: "Paige Shin",
    specialty: "Mobile Developer",
  },
  my_services: {
    service_list: [
      {
        service: "React Development",
        description:
          "리액트와 타입스크립트를 이용하여 간단한 웹앱을 만들 수 있습니다. 랜딩페이지, 회사소개페이지, 관리자페이지 정도는 제가 주로 빌딩을 해왔습니다. 주로 리덕스를 상태괸리 라이브러리로 사용합니다.",
      },
      {
        service: "iOS Development",
        description:
          "Swift Storyboard 기반의 앱을 회사에서 3개를 진행한 경험이 있고 1개의 storyboard앱을 개인적으로 만들어서 출시한 적이 있습니다. 최근에는 SwiftUI를 공부하여 1개의 앱을 앱스토어에 출시하였습니다.",
      },
      {
        service: "Node.js Development",
        description:
          "회사에서 클라이언트 개발을 하면서 동시에 node.js로 rest api 서버를 만들었습니다. node.js로 번역기 같은 툴을 이용하여 앱개발을 하고 퍼페티어를 이용해서 데이터를 수집할 때가 많습니다.",
      },
    ],
    what_i_do: "What I do",
    portfolio_button_title: "포트폴리오 보기",
    portfolio_link:
      "https://lace-gouda-496.notion.site/v4-Korean-2ea63181bbf84c0ea321902bcca47879",
  },
  about_me: {
    title: "Who I am",
    specialty: "Mobile Dev",
    about_me_list: [
      "저는 앱 개발 하는 것을 좋아합니다. 스타트업 문화에도 애정이 있어서 스타트업을 선호합니다.",
      "과거 프랑스에서 8년을 살았던 경험때문에 앱 하나를 내면 최소한 영어, 프랑스어, 한국어는 꼭 지원합니다.",
    ],
  },
  portfolio: {
    title: "My Work",
    subtitle: "",
    portfolio_list: [
      {
        link: "https://docs.google.com/presentation/d/11kY9Z5pNgBqW0yVD4oy1VC2_RHLxEDY10dF-qqMykFo/edit?usp=sharing",
        imageURL: "1.png",
        meta: "bbomi app",
      },
      {
        link: "https://docs.google.com/presentation/d/1-KsD9aa00tffadpyU8vyjDaLN9h1c9_Vi234gA_oFI4/edit?usp=sharing",
        imageURL: "2.png",
        meta: "bbomi web",
      },
      {
        link: "https://docs.google.com/presentation/d/1n800DxU4nF7kiE3D9fZNMpDsJdSABoz3cTMk8GRFes4/edit?usp=sharing",
        imageURL: "3.png",
        meta: "reminiscence",
      },
      {
        link: "https://docs.google.com/presentation/d/1i-getrZJzjdQnoTUM3ZUwa_Y-ptfckCMNgQbQAtignc/edit?usp=sharing",
        imageURL: "4.png",
        meta: "SNU Test",
      },
      {
        link: "https://docs.google.com/presentation/d/1Z89BX8QHDZYBMf01WNxGLbkOqzy7429QVqbUBAFtn6c/edit?usp=sharing",
        imageURL: "5.png",
        meta: "Ume",
      },
      {
        link: "https://docs.google.com/presentation/d/1MMggY-2lSCgK1_GfopS_x9lNnPkpeyZChRvSfECNnV4/edit?usp=sharing",
        imageURL: "6.png",
        meta: "Journal",
      },
      {
        link: "https://docs.google.com/presentation/d/1e_Lsrj5V320TbsZq6iWuU_rRo28SO3abwKsryyyL9NU/edit?usp=sharing",
        imageURL: "7.png",
        meta: "Japanese Kanji",
      },
      {
        link: "https://docs.google.com/presentation/d/1EmbOjzQlzKTdGWCrlqVPQSrVeM9IzSZN84NAZP0CrX4/edit?usp=sharing",
        imageURL: "8.png",
        meta: "Daily Korean Android",
      },
      {
        link: "https://docs.google.com/presentation/d/1amYwOGqJXSGEPUEewkWPZsxN6yf45cQLJpyquEmKijI/edit?usp=sharing",
        imageURL: "9.png",
        meta: "Daily Korean iOS",
      },
      {
        link: "https://docs.google.com/presentation/d/1wsjZv8ZYf-TO-da6zoiDGvFm-FlKmqwJuIDyWPc2gG0/edit?usp=sharing",
        imageURL: "10.png",
        meta: "English word",
      },
      {
        link: "https://docs.google.com/presentation/d/1NFN9c36KjpibCtHX0MIdSqn_qyICdXwAci1ythqQiHs/edit?usp=sharing",
        imageURL: "11.png",
        meta: "Meyou iOS App",
      },
      {
        link: "https://docs.google.com/presentation/d/1a3S5MyjiprcAUL3JC6jUaEFtpFqQHXgoiWb5CnqI3a4/edit?usp=sharing",
        imageURL: "12.png",
        meta: "Meyou Web",
      },
      {
        link: "https://www.npmjs.com/package/appstore-comments-to-csv",
        imageURL: "13.png",
        meta: "npm package appstore comments to csv",
      },
      {
        link: "https://www.npmjs.com/package/ios_translator",
        imageURL: "14.png",
        meta: "npm package ios translator, generage localizable.strings",
      },
      {
        link: "https://www.npmjs.com/package/aos_translator",
        imageURL: "15.png",
        meta: "npm package android translator, generage localizable.strings",
      },
      {
        link: "https://www.npmjs.com/package/playstore-comments-to-csv",
        imageURL: "16.png",
        meta: "npm package playstore comments to csv",
      },
      {
        link: "https://apps.apple.com/us/app/sleepground/id1595343211",
        imageURL: "17.png",
        meta: "Sleep Ground",
      },
      {
        link: "https://apps.apple.com/us/app/dr-dozy-interactive-sleepwaves/id1609959432",
        imageURL: "18.png",
        meta: "Dr.dozy",
      },
      {
        link: "https://apps.apple.com/kr/app/%EB%AF%B8%EB%9D%BC%ED%81%B4%EB%82%98%EC%9E%87-4%EC%8B%9C%EA%B0%84%EB%B0%98-%EA%B3%A0%EB%86%8D%EC%B6%95-%EC%8A%AC%EB%A6%BD%EC%83%B7/id1619362440",
        imageURL: "19.png",
        meta: "Miracle Night",
      },
      {
        link: "https://apps.apple.com/us/app/my-weight/id1659629363",
        imageURL: "20.png",
        meta: "Weight Reminder",
      },
      {
        link: "https://apps.apple.com/us/app/hide-weblink-url-lock-app/id6446258565",
        imageURL: "21.png",
        meta: "Secret Link",
      },
      {
        link: "https://apps.apple.com/us/app/bulking-with-ideal-protein/id6447008177",
        imageURL: "22.png",
        meta: "Protein Tracker",
      },
      {
        link: "https://apps.apple.com/us/app/compound-interest-calculator/id6449872832",
        imageURL: "23.png",
        meta: "Compound Interest Calculator",
      },
      {
        link: "https://www.npmjs.com/package/ios-keyword-extractor",
        imageURL: "24.png",
        meta: "iOS Keyword Extractor",
      },
      {
        link: "https://apps.apple.com/us/app/spiritual-awakening-grow/id6451087370",
        imageURL: "25.png",
        meta: "Spiritual Awakening",
      },
      {
        link: "https://apps.apple.com/us/app/sticky-todo-app/id6479539544",
        imageURL: "26.png",
        meta: "Sticky Todo iOS",
      },
      {
        link: "https://play.google.com/store/apps/details?id=com.paigesoftware.sticky_todo",
        imageURL: "27.png",
        meta: "Sticky Todo Android",
      },
      {
        link: "https://apps.apple.com/us/app/nightly-ai-sleep-sounds/id6478567003",
        imageURL: "28.png",
        meta: "Nightly SwiftUI",
      },
    ],
  },
  email: "paigeshin1991@gmail.com",
  phone: "010-8013-3112",
  github: "https://github.com/paigeshin?tab=repositories",
  medium: "https://medium.com/@paigeshin1991",
};
