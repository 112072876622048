import React from "react";

const AboutMe = ({ data }) => {
  const aboutMeItems = data.about_me.about_me_list.map((aboutMe) => {
    return <p key={aboutMe}>{aboutMe}</p>;
  });

  return (
    <section className="about-me" id="about">
      <h2 className="section__title section__title--about">
        {data.about_me.title}
      </h2>
      <p className="section__subtitle section__subtitle--about">
        {data.about_me.specialty}
      </p>

      <div className="about-me__body">{aboutMeItems}</div>

      <img
        src="img/profile_photo_2.jpeg"
        alt="Paige Standing"
        className="about-me__img"
      />
    </section>
  );
};

export default AboutMe;
