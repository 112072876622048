import React, { useState, useEffect } from "react";
import Analytics from "react-ga";

const Navigation = ({ data }) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    document.body.classList.toggle("nav-open", open);
  }, [open]);

  const navItems = data.nav_items.map((navigation) => {
    return (
      <li key={navigation.link} className="nav__item">
        <a
          href={navigation.link}
          className="nav__link"
          onClick={() => {
            Analytics.event({
              category: `${navigation.name}`,
              action: `Click ${navigation.name} Link`,
            });
            setOpen(!open);
          }}
        >
          {navigation.name}
        </a>
      </li>
    );
  });

  return (
    <header>
      <div className="logo">
        {/* <img src="img/logo.png" alt="Logo Image"> */}
      </div>
      <button
        className="nav-toggle"
        aria-label="toggle navigation"
        onClick={() => {
          Analytics.event({
            category: "Hamburger",
            action: "Click Hamburger Button",
          });
          setOpen(!open);
        }}
      >
        <span className="hamburger"></span>
      </button>

      <nav className="nav">
        <ul className="nav__list">{navItems}</ul>
      </nav>
    </header>
  );
};

export default Navigation;
