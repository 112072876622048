exports.french = {
  nav_items: [
    {
      link: "#home",
      name: "Accueil",
    },
    {
      link: "#services",
      name: "Services",
    },
    {
      link: "#about",
      name: "A propos de moi",
    },
    {
      link: "#work",
      name: "Mon Travail",
    },
  ],
  introduction: {
    title: "Bonjour",
    name: "Je suis Paige Shin",
    specialty: "Développeur du service mobile",
  },
  my_services: {
    what_i_do: "Que je fais",
    service_list: [
      {
        service: "Développement iOS",
        description:
          "J'ai développé 3 applications basées sur Swift Storyboard dans mon entreprise, et j'ai personnellement créé et publié une application de storyboard. Récemment, j'ai étudié SwiftUI et publié 1 application",
      },
      {
        service: "Développement Node.js",
        description:
          "Lors du développement d'un client, j'ai créé aussi un serveur REST API avec node.js. Avec node.js, les applications sont développées à l'aide d'outils tels que des traducteurs, et les données sont collectées à l'aide de Puppeteer.",
      },
      {
        service: "Dévelopment Web",
        description:
          "Avec React et Typescript, je peux créer un site-web d'administration, d'introduction et la page d'accueil. J'utilise principalement redux pour la gestion de l'état de l'application.",
      },
    ],
    portfolio_button_title: "Voir Portfolio",
    portfolio_link:
      "https://lace-gouda-496.notion.site/D-veloppeur-du-logiciel-v4-French-3d2acb54427c42a7ae3233803746d9f9",
  },
  about_me: {
    title: "Qui je suis",
    specialty: "Développeur du service mobile",
    about_me_list: [
      "J'adore apprendre à créer des applications et je suis très intéressé par les startups. Ma passion pour le développement mobile prouve mon amour pour la culture start-up.",
      "J'ai vécu en France pendant plus de huit ans. Ainsi, lorsque je développe des applications, je prends généralement en charge l'anglais, le français et le coréen. J'ai toujours pour objectif de développer un produit qui puisse être largement utilisé.",
    ],
  },
  portfolio: {
    title: "Mes Travaux",
    subtitle: "",
    portfolio_list: [
      {
        link: "https://docs.google.com/presentation/d/12mPpoLKecRAVgz_3Z3A6VUbi7R0VkuQWhHZmk2sXl70/edit?usp=sharing",
        imageURL: "1.png",
        meta: "bbomi app",
      },
      {
        link: "https://docs.google.com/presentation/d/1THjU9YgncCPDjXMPy9rcyShb72BSdm0cvONFP6tBjPw/edit?usp=sharing",
        imageURL: "2.png",
        meta: "bbomi web",
      },
      {
        link: "https://docs.google.com/presentation/d/1sJdo1MH7_CdGNhlzY6U5mRAdkLODTmui_Q3ASI57EBA/edit?usp=sharing",
        imageURL: "3.png",
        meta: "reminiscence",
      },
      {
        link: "https://docs.google.com/presentation/d/1RU8rkKe3bgZLAEAgKB37s3veScXSlMfRfHrWgqiVq3s/edit?usp=sharing",
        imageURL: "4.png",
        meta: "SNU Test",
      },
      {
        link: "https://docs.google.com/presentation/d/1GZ1xjWtg819YopxVZJMw2Hvu_Wt56BsPyhlpERbqvs4/edit?usp=sharing",
        imageURL: "5.png",
        meta: "Ume",
      },
      {
        link: "https://docs.google.com/presentation/d/1FDVd-Hyfo2-DLqckYobdPLDJPKxW-tLKxOmaN7Hs99E/edit?usp=sharing",
        imageURL: "6.png",
        meta: "Journal",
      },
      {
        link: "https://docs.google.com/presentation/d/12gUi302c9vGjtrtfuFpK4vaBLCmbA_nTgpD9xjUZnWI/edit?usp=sharing",
        imageURL: "7.png",
        meta: "Japanese Kanji",
      },
      {
        link: "https://docs.google.com/presentation/d/1kCZpnzz_ALnQv8d93P5hXBEZ21a3YswSh-oZZBgqR7Y/edit?usp=sharing",
        imageURL: "8.png",
        meta: "Daily Korean Android",
      },
      {
        link: "https://docs.google.com/presentation/d/1VIuwrfZXGfEUZPqvEbL_tErAEIn8xlJTqAz1kuXvzDk/edit?usp=sharing",
        imageURL: "9.png",
        meta: "Daily Korean iOS",
      },
      {
        link: "https://docs.google.com/presentation/d/1L4SmDtjdDGCwRtWWWuc3jJNTTUFe7xBAyrRspNzDNf8/edit?usp=sharing",
        imageURL: "10.png",
        meta: "English word",
      },
      {
        link: "https://docs.google.com/presentation/d/1_qEgGLZUuqlR1zUxaquFrolOw1Y2eUqG69KcL8h38G4/edit?usp=sharing",
        imageURL: "11.png",
        meta: "Meyou iOS App",
      },
      {
        link: "https://docs.google.com/presentation/d/1ONXobFssztwEhtd8ue61Xb03vZKdHgfs3VkBiY-wd9Y/edit?usp=sharing",
        imageURL: "12.png",
        meta: "Meyou Web",
      },
      {
        link: "https://www.npmjs.com/package/appstore-comments-to-csv",
        imageURL: "13.png",
        meta: "npm package appstore comments to csv",
      },
      {
        link: "https://www.npmjs.com/package/ios_translator",
        imageURL: "14.png",
        meta: "npm package ios translator, generage localizable.strings",
      },
      {
        link: "https://www.npmjs.com/package/aos_translator",
        imageURL: "15.png",
        meta: "npm package android translator, generage localizable.strings",
      },
      {
        link: "https://www.npmjs.com/package/playstore-comments-to-csv",
        imageURL: "16.png",
        meta: "npm package playstore comments to csv",
      },
      {
        link: "https://apps.apple.com/us/app/sleepground/id1595343211",
        imageURL: "17.png",
        meta: "Sleep Ground",
      },
      {
        link: "https://apps.apple.com/us/app/dr-dozy-interactive-sleepwaves/id1609959432",
        imageURL: "18.png",
        meta: "Dr.dozy",
      },
      {
        link: "https://apps.apple.com/kr/app/%EB%AF%B8%EB%9D%BC%ED%81%B4%EB%82%98%EC%9E%87-4%EC%8B%9C%EA%B0%84%EB%B0%98-%EA%B3%A0%EB%86%8D%EC%B6%95-%EC%8A%AC%EB%A6%BD%EC%83%B7/id1619362440",
        imageURL: "19.png",
        meta: "Miracle Night",
      },
      {
        link: "https://apps.apple.com/us/app/my-weight/id1659629363",
        imageURL: "20.png",
        meta: "Weight Reminder",
      },
      {
        link: "https://apps.apple.com/us/app/hide-weblink-url-lock-app/id6446258565",
        imageURL: "21.png",
        meta: "Secret Link",
      },
      {
        link: "https://apps.apple.com/us/app/bulking-with-ideal-protein/id6447008177",
        imageURL: "22.png",
        meta: "Protein Tracker",
      },
      {
        link: "https://apps.apple.com/us/app/compound-interest-calculator/id6449872832",
        imageURL: "23.png",
        meta: "Compound Interest Calculator",
      },
      {
        link: "https://www.npmjs.com/package/ios-keyword-extractor",
        imageURL: "24.png",
        meta: "iOS Keyword Extractor",
      },
      {
        link: "https://apps.apple.com/us/app/spiritual-awakening-grow/id6451087370",
        imageURL: "25.png",
        meta: "Spiritual Awakening",
      },
      {
        link: "https://apps.apple.com/us/app/sticky-todo-app/id6479539544",
        imageURL: "26.png",
        meta: "Sticky Todo iOS",
      },
      {
        link: "https://play.google.com/store/apps/details?id=com.paigesoftware.sticky_todo",
        imageURL: "27.png",
        meta: "Sticky Todo Android",
      },
      {
        link: "https://apps.apple.com/us/app/nightly-ai-sleep-sounds/id6478567003",
        imageURL: "28.png",
        meta: "Nightly SwiftUI",
      },
    ],
  },
  email: "paigeshin1991@gmail.com",
  phone: "+821080133112",
  github: "https://github.com/paigeshin?tab=repositories",
  medium: "https://medium.com/@paigeshin1991",
};
