import { useEffect } from "react";
import Analytics from "react-ga";
import "./App.css";
import AboutMe from "./components/AboutMe";
import Footer from "./components/Footer";
import Introduction from "./components/Introduction";
import Navigation from "./components/Navigation";
import MyService from "./components/MyService";
import MyWork from "./components/MyWork";
import { BrowserRouter, Routes, Route } from "react-router-dom";
const englishData = require("./data/english").english;
const frenchData = require("./data/french").french;
const koreanData = require("./data/korean").korean;

// Change Data
englishData.portfolio.portfolio_list =
  englishData.portfolio.portfolio_list.reverse();
frenchData.portfolio.portfolio_list =
  frenchData.portfolio.portfolio_list.reverse();
koreanData.portfolio.portfolio_list =
  koreanData.portfolio.portfolio_list.reverse();

const userLanguage = window.navigator.userLanguage || window.navigator.language;
let sortedData;
if (userLanguage === "ko") {
  sortedData = koreanData;
} else if (userLanguage === "fr") {
  sortedData = frenchData;
} else {
  sortedData = englishData;
}

const App = () => {
  useEffect(() => {
    Analytics.initialize("UA-222316011-1");
    Analytics.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    // <Scaffold data={sortedData} />
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/*" element={<Scaffold data={sortedData} />} />
          <Route path="/en" element={<Scaffold data={englishData} />} />
          <Route path="/ko" element={<Scaffold data={koreanData} />} />
          <Route path="/fr" element={<Scaffold data={frenchData} />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

const Scaffold = ({ data }) => {
  return (
    <>
      <Navigation data={data} />
      <Introduction data={data} />
      <MyService data={data} />
      <AboutMe data={data} />
      <MyWork data={data} />
      <Footer data={data} />
    </>
  );
};

export default App;
