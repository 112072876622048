import React from "react";
import Analytics from "react-ga";

const MyService = ({ data }) => {
  const serviceItems = data.my_services.service_list.map((service) => {
    return (
      <div key={service.service} className="service">
        <h3>{service.service}</h3>
        <p>{service.description}</p>
      </div>
    );
  });

  return (
    <section className="my-services" id="services">
      <h2 className="section__title section__title--services">
        {data.my_services.what_i_do}
      </h2>
      <div className="services">{serviceItems}</div>

      <a
        href={data.my_services.portfolio_link}
        className="btn"
        onClick={() => {
          Analytics.event({
            category: "Portfolio",
            action: "Click Portfolio Link",
          });
        }}
      >
        {data.my_services.portfolio_button_title}
      </a>
    </section>
  );
};

export default MyService;
