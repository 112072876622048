exports.english = {
  nav_items: [
    {
      link: "#home",
      name: "Home",
    },
    {
      link: "#services",
      name: "My Services",
    },
    {
      link: "#about",
      name: "About me",
    },
    {
      link: "#work",
      name: "My Work",
    },
  ],
  introduction: {
    title: "Hi, I am ",
    name: "Paige shin",
    specialty: "Mobile Dev",
  },
  my_services: {
    service_list: [
      {
        service: "React Development",
        description:
          "I can build simple web app with react and typescript. I mostly build admin webpage, landing page and industry introduction webpage. I use redux for managing app state.",
      },
      {
        service: "iOS Development",
        description:
          "I usually develop with Swift. I built 3 stroyboard based ios apps for the compnay and 1 storyboard app for personal uses. Recently, I've learned SwiftUI and published 1 app based on SwiftUI.",
      },

      {
        service: "Node.js Development",
        description:
          "I have experience building mostly rest API. I use Google Cloud Platform to deploy my app and Google Cloud functions. I also automatize some of my works using node.js puppeteer.",
      },
    ],
    what_i_do: "What I do",
    portfolio_button_title: "Go to Portfolio",
    portfolio_link:
      "https://lace-gouda-496.notion.site/Fast-Learner-v4-English-b1c5348d26354bc799b3a1d3c7a43d04",
  },
  about_me: {
    title: "Who I am",
    specialty: "Mobile Developer",
    about_me_list: [
      "I love to learn building applications and I'm very interested in startup business. My passion for mobile development proves my love towards start-up culture.",
      "I've lived in France for more than 8 years. So, when I develop apps, I usually support for English, French and Korean. I always aim to develop a product which can be widely used.",
    ],
  },
  portfolio: {
    title: "My Work",
    subtitle: "A selection of my range of work",
    portfolio_list: [
      {
        link: "https://docs.google.com/presentation/d/1MCVLE1oqi5A4SGE-kZMqD2iipey9OwCReOLEBn5XZkY/edit?usp=sharing",
        imageURL: "1.png",
        meta: "bbomi app",
      },
      {
        link: "https://docs.google.com/presentation/d/1_cbCISU0Lp18duYku26iym6d2Rb_A0dNrhvhRzoRxSk/edit?usp=sharing",
        imageURL: "2.png",
        meta: "bbomi web",
      },
      {
        link: "https://docs.google.com/presentation/d/1Mdcgdv7aaeQlBuqBypqU0TY7jJnw1e_sb0BTdERB_lE/edit?usp=sharing",
        imageURL: "3.png",
        meta: "reminiscence",
      },
      {
        link: "https://docs.google.com/presentation/d/1RTvRnP9dumThxozRIGWe4O5_g93LuiWJUNKNIxtI7Bo/edit?usp=sharing",
        imageURL: "4.png",
        meta: "SNU Test",
      },
      {
        link: "https://docs.google.com/presentation/d/1qy3_n5dCy8OEN53tWReOrkvfB0A0GWa_zufmqhyXn2w/edit?usp=sharing",
        imageURL: "5.png",
        meta: "Ume",
      },
      {
        link: "https://docs.google.com/presentation/d/1l_Xdz4OOWGdR1cp4DRcjVhdA4kooBrzG8QmJibTq-1E/edit?usp=sharing",
        imageURL: "6.png",
        meta: "Journal",
      },
      {
        link: "https://docs.google.com/presentation/d/1OaqfGxDGx2rOzImUVuL1h46Dyfp1gwwr64o-xL-rXXU/edit?usp=sharing",
        imageURL: "7.png",
        meta: "Japanese Kanji",
      },
      {
        link: "https://docs.google.com/presentation/d/1ZCwRfE9Yv6Xe34rezOjOhkhFGuB9YdeaNXlywvQDGZA/edit?usp=sharing",
        imageURL: "8.png",
        meta: "Daily Korean Android",
      },
      {
        link: "https://docs.google.com/presentation/d/1Ssia_UlQX--n-0S4-GQC8L1LrYo5iZ_Un3pRXajgUq8/edit?usp=sharing",
        imageURL: "9.png",
        meta: "Daily Korean iOS",
      },
      {
        link: "https://docs.google.com/presentation/d/1ePttbmUgmZw1N3GwJ6fMgyfv47WS4grxAbodqDeNJco/edit?usp=sharing",
        imageURL: "10.png",
        meta: "English word",
      },
      {
        link: "https://docs.google.com/presentation/d/1RaknFBjW70i8I23GugTV_5do3zR3FERvE574J2xAVmk/edit?usp=sharing",
        imageURL: "11.png",
        meta: "Meyou iOS App",
      },
      {
        link: "https://docs.google.com/presentation/d/1_-bu7Kwmmxi7w4o3RvgjVIBYusTZZyc429JSnJcLtRo/edit?usp=sharing",
        imageURL: "12.png",
        meta: "Meyou Web",
      },
      {
        link: "https://www.npmjs.com/package/appstore-comments-to-csv",
        imageURL: "13.png",
        meta: "npm package appstore comments to csv",
      },
      {
        link: "https://www.npmjs.com/package/ios_translator",
        imageURL: "14.png",
        meta: "npm package ios translator, generage localizable.strings",
      },
      {
        link: "https://www.npmjs.com/package/aos_translator",
        imageURL: "15.png",
        meta: "npm package android translator, generage localizable.strings",
      },
      {
        link: "https://www.npmjs.com/package/playstore-comments-to-csv",
        imageURL: "16.png",
        meta: "npm package playstore comments to csv",
      },
      {
        link: "https://apps.apple.com/us/app/sleepground/id1595343211",
        imageURL: "17.png",
        meta: "Sleep Ground",
      },
      {
        link: "https://apps.apple.com/us/app/dr-dozy-interactive-sleepwaves/id1609959432",
        imageURL: "18.png",
        meta: "Dr.dozy",
      },
      {
        link: "https://apps.apple.com/kr/app/%EB%AF%B8%EB%9D%BC%ED%81%B4%EB%82%98%EC%9E%87-4%EC%8B%9C%EA%B0%84%EB%B0%98-%EA%B3%A0%EB%86%8D%EC%B6%95-%EC%8A%AC%EB%A6%BD%EC%83%B7/id1619362440",
        imageURL: "19.png",
        meta: "Miracle Night",
      },
      {
        link: "https://apps.apple.com/us/app/my-weight/id1659629363",
        imageURL: "20.png",
        meta: "Weight Reminder",
      },
      {
        link: "https://apps.apple.com/us/app/hide-weblink-url-lock-app/id6446258565",
        imageURL: "21.png",
        meta: "Secret Link",
      },
      {
        link: "https://apps.apple.com/us/app/bulking-with-ideal-protein/id6447008177",
        imageURL: "22.png",
        meta: "Protein Tracker",
      },
      {
        link: "https://apps.apple.com/us/app/compound-interest-calculator/id6449872832",
        imageURL: "23.png",
        meta: "Compound Interest Calculator",
      },
      {
        link: "https://www.npmjs.com/package/ios-keyword-extractor",
        imageURL: "24.png",
        meta: "iOS Keyword Extractor",
      },
      {
        link: "https://apps.apple.com/us/app/spiritual-awakening-grow/id6451087370",
        imageURL: "25.png",
        meta: "Spiritual Awakening",
      },
      {
        link: "https://apps.apple.com/us/app/sticky-todo-app/id6479539544",
        imageURL: "26.png",
        meta: "Sticky Todo iOS",
      },
      {
        link: "https://play.google.com/store/apps/details?id=com.paigesoftware.sticky_todo",
        imageURL: "27.png",
        meta: "Sticky Todo Android",
      },
      {
        link: "https://apps.apple.com/us/app/nightly-ai-sleep-sounds/id6478567003",
        imageURL: "28.png",
        meta: "Nightly SwiftUI",
      },
    ],
  },
  email: "paigeshin1991@gmail.com",
  phone: "+821080133112",
  github: "https://github.com/paigeshin?tab=repositories",
  medium: "https://medium.com/@paigeshin1991",
};
