import React from "react";

const Introduction = ({ data }) => {
  return (
    <section className="intro" id="home">
      <h1 className="section__title section__title--intro">
        {data.introduction.title}
        <strong> {data.introduction.name}</strong>
      </h1>
      <p className="section__subtitle section__subtitle--intro">
        {data.introduction.specialty}
      </p>
      <img
        src="img/profile_photo_1.jpeg"
        alt="Paige smiling"
        className="intro__img"
      />
    </section>
  );
};

export default Introduction;
