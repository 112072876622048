import React from "react";
import Analytics from "react-ga";

const Footer = ({ data }) => {
  return (
    <footer className="footer">
      <a href={`mailto:${data.email}`} className="footer__link">
        {data.email}
      </a>
      <ul className="social-list">
        {/* <li className="social-list__item">
          <a className="social-list__link" href="https://codepen.io">
            <i className="fab fa-codepen"></i>
          </a>
        </li>
        <li className="social-list__item">
          <a className="social-list__link" href="http://dribbble.com">
            <i className="fab fa-dribbble"></i>
          </a>
        </li>
        <li className="social-list__item">
          <a className="social-list__link" href="https://twitter.com">
            <i className="fab fa-twitter"></i>
          </a>
        </li> */}
        <li className="social-list__item">
          <a
            className="social-list__link"
            href={data.github}
            onClick={() => {
              Analytics.event({
                category: "github",
                action: "Click github link",
              });
            }}
          >
            <i className="fab fa-github"></i>
          </a>
        </li>
        <li>
          <a
            className="social-list__link"
            href={data.medium}
            onClick={() => {
              Analytics.event({
                category: "medium",
                action: "Click medium link",
              });
            }}
          >
            <i className="fab fa-medium"></i>
          </a>
        </li>
      </ul>
    </footer>
  );
};

export default Footer;
