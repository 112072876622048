import React from "react";
import Analytics from "react-ga";

const MyWork = ({ data }) => {
  const myWorkItems = data.portfolio.portfolio_list.map((portfolio) => {
    return (
      <a
        key={portfolio.link}
        href={portfolio.link}
        className="portfolio__item"
        onClick={() => {
          Analytics.event({
            category: `${portfolio.meta}`,
            action: `Click ${portfolio.meta}`,
          });
        }}
      >
        <img
          src={`img/portfolio/${portfolio.imageURL}`}
          alt="my works"
          className="portfolio__img"
        />
      </a>
    );
  });

  return (
    <section className="my-work" id="work">
      <h2 className="section__title section__title--work">
        {data.portfolio.title}
      </h2>
      <p className="section__subtitle section__subtitle--work">
        {data.portfolio.subtitle}
      </p>

      <div className="portfolio">{myWorkItems}</div>
    </section>
  );
};

export default MyWork;
